import { useSelector, useDispatch } from 'react-redux';
import {
  paymentsFetchInvoices,
  paymentsSetPagination,
} from '../../store/actions/paymentsActions';

const useInvoiceTable = () => {
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { pagination } = useSelector(state => state.paymentsState);
  const dispatch = useDispatch();

  const resetPagination = () => {
    dispatch(
      paymentsSetPagination({
        ...pagination,
        page: 1,
      })
    );
  };

  const updateInvoices = ({ page, pageSize, search, option }) => {
    dispatch(
      paymentsFetchInvoices({
        businessId,
        option,
        pageSize,
        page,
        search,
      })
    );
  };

  return {
    resetPagination,
    updateInvoices,
  };
};

export default useInvoiceTable;
