import React from 'react';
import { AppBar, Tabs, Tab, makeStyles, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { PAYMENTS_TABS } from '../../helpers/constants';
import { setActiveTab } from '../../store/actions/paymentsActions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 60,
    padding: '0 20px',
    background: theme.palette.primary.contrastText,
    position: 'sticky',
    top: 56,
    alignItems: 'center',
    zIndex: 9,
  },
  appBar: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.text.primary,
    zIndex: 1,
    width: '100%',
  },
  tab: {
    height: 60,
    alignItems: 'center',
    padding: '15px 0px',
  },
}));

const TABS = [
  {
    label: 'Mis facturas',
    value: PAYMENTS_TABS.INBOX,
    'data-tour': 'PAYMENTS_GUIDEDTOUR_INBOX',
  },
  {
    label: 'Programadas',
    value: PAYMENTS_TABS.SCHEDULED,
  },
  {
    label: 'Pagadas',
    value: PAYMENTS_TABS.PAID,
  },
  {
    label: 'Archivadas',
    value: PAYMENTS_TABS.ARCHIVED,
    'data-tour': 'PAYMENTS_GUIDEDTOUR_ARCHIVED',
  },
];

const PaymentsTabs = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTab } = props;

  const handleChange = (event, newTab) => {
    if (activeTab !== newTab) dispatch(setActiveTab(newTab));
  };

  return (
    <Grid container item className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
        >
          {/* eslint-disable react/jsx-props-no-spreading */}
          {TABS.map(item => (
            <Tab className={classes.tab} key={item.value} {...item} />
          ))}
        </Tabs>
      </AppBar>
    </Grid>
  );
};

PaymentsTabs.defaultProps = {
  activeTab: PAYMENTS_TABS[0],
};

PaymentsTabs.propTypes = {
  activeTab: PropTypes.oneOf(PAYMENTS_TABS),
};

export default PaymentsTabs;
