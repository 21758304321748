import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { Typography, Fade, Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { convertSpacingToCss } from '../helpers/stylesHelpers';
import Panel from './elements/Panel';
import CustomButton from './elements/CustomButton';
import {
  fetchContactDetails,
  createContactDetail,
  updateContactDetail,
  deleteContactDetail,
} from '../store/actions/contactDetailActions';
import ConfirmDialog from './dialogs/ConfirmDialog';
import ContactDetailDialog from './dialogs/ContactDetailDialog';

import { SEPARATOR } from '../theme/otherColors';

const useStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: SEPARATOR,
  },
  emptyContainer: {
    paddingLeft: 30,
  },
});

const ContactDetails = ({
  contactableId,
  contactableType,
  title,
  titleAction,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedContactDetail, setSelectedContactDetail] = useState(null);
  const [selectedContactDetailId, setSelectedContactDetailId] = useState(null);
  const [deleteContactDialogIsOpen, setDeleteContactDialogIsOpen] =
    useState(false);
  const [contactDetailDialogIsOpen, setContactDetailDialogIsOpen] =
    useState(false);

  const {
    [`${contactableType}ContactDetails`]: contactDetails,
    [`${contactableType}FetchingContactDetails`]: fetchingContactDetails,
  } = useSelector(state => state.contactDetailsState);

  const handleDeleteContactDetail = () => {
    dispatch(
      deleteContactDetail(
        contactableId,
        contactableType,
        selectedContactDetailId
      )
    );
    setDeleteContactDialogIsOpen(false);
  };

  const handleSubmitContactDetail = contactDetailData => {
    const contactDetailObject = { ...contactDetailData };

    if (contactDetailObject.id) {
      const {
        id: contactDetailId,
        createdAt,
        updatedAt,
        ...restOfContactDetail
      } = contactDetailObject;
      delete restOfContactDetail.contactableId;
      delete restOfContactDetail.contactableType;
      delete restOfContactDetail.type;
      delete restOfContactDetail.tableData;
      dispatch(
        updateContactDetail(
          contactableId,
          contactableType,
          contactDetailId,
          restOfContactDetail
        )
      );
    } else {
      dispatch(
        createContactDetail(contactableId, contactableType, contactDetailObject)
      );
    }
  };

  const handleShowDeleteContactDialog = contactDetailId => {
    setSelectedContactDetailId(contactDetailId);
    setDeleteContactDialogIsOpen(true);
  };

  const handleShowContactDetailDialog = contactDetailId => {
    const contact = contactDetails.find(
      contactDetail => contactDetail.id === contactDetailId
    );
    setSelectedContactDetail(contact);
    setSelectedContactDetailId(contactDetailId);
    setContactDetailDialogIsOpen(true);
  };

  useEffect(() => {
    if (contactableId) {
      dispatch(fetchContactDetails(contactableId, contactableType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactableId]);

  const getColumns = () => {
    return [
      {
        title: 'Nombre',
        field: 'name',
        render: ({ name }) => (
          <Typography variant="body1" component="div" noWrap>
            {name}
          </Typography>
        ),
      },
      {
        title: 'Email',
        field: 'email',
        render: ({ email }) => (
          <Typography variant="body1" component="div" noWrap>
            {email}
          </Typography>
        ),
      },
      {
        title: 'Cargo',
        field: 'position',
        render: ({ position }) => (
          <Typography variant="body1" component="div" noWrap>
            {position}
          </Typography>
        ),
      },
      {
        title: 'Teléfono',
        field: 'phone',
        render: ({ phone }) => (
          <Typography variant="body1" component="div" noWrap>
            {phone}
          </Typography>
        ),
      },
      {
        align: 'right',
        render: ({ id }) => (
          <>
            <IconButton
              aria-label="editar"
              onClick={() => handleShowContactDetailDialog(id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="editar"
              onClick={() => handleShowDeleteContactDialog(id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ];
  };

  return (
    <Fade in timeout={400}>
      <Grid item>
        {deleteContactDialogIsOpen && (
          <ConfirmDialog
            isOpen={deleteContactDialogIsOpen}
            handleClose={() => setDeleteContactDialogIsOpen(false)}
            title="Eliminar contacto"
            message="¿Seguro deseas eliminar el contacto?"
            buttonLabel="Eliminar contacto"
            buttonOnAccept={handleDeleteContactDetail}
          />
        )}
        {contactDetailDialogIsOpen && (
          <ContactDetailDialog
            contactDetail={selectedContactDetail}
            type={contactableType}
            open={contactDetailDialogIsOpen}
            onClose={() => setContactDetailDialogIsOpen(false)}
            onSubmit={handleSubmitContactDetail}
            sourceId={selectedContactDetailId}
          />
        )}
        <Box className={classes.containerBody}>
          <Panel
            title={title}
            variant="green-title"
            titlePadding="xl xl lg"
            contentPadding="zero zero xl zero"
            titleAction={titleAction}
            actions={
              contactableId ? (
                <CustomButton
                  variant="orange-lined"
                  onClick={handleShowContactDetailDialog}
                >
                  Agregar Contacto
                </CustomButton>
              ) : null
            }
          >
            <MaterialTable
              style={{ width: '100%', boxShadow: 'none' }}
              columns={getColumns()}
              data={contactDetails}
              isLoading={fetchingContactDetails}
              localization={{
                body: {
                  emptyDataSourceMessage: 'No hay contactos para mostrar',
                },
              }}
              options={{
                paging: false,
                search: false,
                showTitle: false,
                sorting: true,
                toolbar: false,
              }}
            />
          </Panel>
        </Box>
      </Grid>
    </Fade>
  );
};

ContactDetails.defaultProps = {
  titleAction: null,
};

ContactDetails.propTypes = {
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.element,
  contactableType: PropTypes.string.isRequired,
  contactableId: PropTypes.number.isRequired,
};

export default ContactDetails;
