import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button, Grid, Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import PaymentsInvoiceTable from './tables/PaymentsInvoiceTable';
import PaymentsInvoiceTableToolbar from './elements/PaymentsInvoiceTableToolbar';
/* import CustomButton from './elements/CustomButton'; */
import PaymentsTableDatePicker from './elements/PaymentsTableDatePicker';
import CountryFormatHelper from './elements/CountryFormatHelper';
import Card from './elements/Card';
import {
  paymentsArchiveInvoice,
  paymentsPayInvoice,
  paymentsChangePaymentDate,
} from '../store/actions/paymentsActions';

import PaymentsResume from './elements/PaymentsResume';
import PaymentsScheduleInvoicesStepper from './PaymentsScheduleInvoicesStepper';
import useInvoiceTable from '../hooks/payments/useInvoiceTable';

import { SEGMENT_EVENTS } from '../helpers/segment/constants';
import { trackSegment } from '../helpers/segment/trackingSegment';

const TABLE_NAME = 'inbox';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: 20,
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
  },
  paymentDateLink: {
    color: theme.palette.secondary.main,
    textDecorationLine: 'underline',
    background: 'none',
    '&:hover, &:active, &:focus,': {
      background: 'none',
    },
  },
  button: {
    background: theme.palette.secondary.contrastText,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '7px 16px',
    borderRadius: 35,
    minHeight: 40,
    margin: '0 16px 0 0',
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.contrastText,
    },
    '&.active': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.contrastText,
    },
  },
  loadCSVButton: {
    margin: '0 16px 0 0',
    ...theme.typography.button,
  },
  paymentDateColumn: {
    minWidth: 115,
  },
}));

const PaymentsInboxTable = React.memo(props => {
  const { countryId, businessId } = props;
  const [showScheduleInvoicesStepper, setShowScheduleInvoicesStepper] =
    useState(false);
  const { selectedInvoices, pagination, totalSelectedInvoices } = useSelector(
    state => state.paymentsState
  );
  const { page, pageSize } = pagination;
  const [search, setSearch] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { resetPagination, updateInvoices } = useInvoiceTable();

  /* const loadCSV = () => {}; */

  const handleArchived = () => {
    dispatch(
      paymentsArchiveInvoice(businessId, selectedInvoices, moment().format())
    );
  };
  const handlePaid = () => {
    dispatch(
      paymentsPayInvoice(businessId, selectedInvoices, moment().format())
    );
  };

  // eslint-disable-next-line no-unused-vars
  /* const addBankDetails = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  // eslint-disable-next-line no-unused-vars
  /* const payInvoice = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  const onChangeDatePicker = ({
    newDate,
    handleDateChange,
    togglePicker,
    invoice,
  }) => {
    const { id: invoiceId } = invoice;
    const formatedDate = newDate.format();
    dispatch(
      paymentsChangePaymentDate(businessId, invoiceId, formatedDate, 'inbox')
    );
    handleDateChange(newDate);
    togglePicker();
  };

  const handleShowScheduleStepper = () => {
    setShowScheduleInvoicesStepper(true);
    trackSegment(SEGMENT_EVENTS.PAYMENT_INVOICE_SELECTED, {
      businessId,
    });
  };

  const rows = [
    {
      position: 50,
      title: 'Fecha de Pago',
      field: 'paymentDetail.paymentDate',
      type: 'date',
      width: '100%',
      align: 'left',
      render: invoice => {
        const { paymentDate } = invoice.paymentDetail;
        if (!paymentDate) return null;
        return (
          <Box className={classes.paymentDateColumn}>
            <PaymentsTableDatePicker
              date={paymentDate}
              onChange={onChangeDatePicker}
              countryId={countryId}
              invoice={invoice}
            >
              <ButtonBase disableRipple className={classes.paymentDateLink}>
                <Box fontWeight="fontWeightBold">
                  <CountryFormatHelper
                    value={paymentDate}
                    variant="longdate"
                    countryId={countryId}
                  />
                </Box>
              </ButtonBase>
            </PaymentsTableDatePicker>
          </Box>
        );
      },
    },
  ];

  const buttons = [
    <Button
      id="archiveButton"
      key="archiveButton"
      variant="contained"
      color="primary"
      size="medium"
      className={clsx(classes.button, {
        active: selectedInvoices.length > 0,
      })}
      onClick={handleArchived}
    >
      Archivar
    </Button>,
    <Button
      id="paidButton"
      key="paidButton"
      variant="contained"
      color="primary"
      size="medium"
      className={clsx(classes.button, {
        active: selectedInvoices.length > 0,
      })}
      onClick={handlePaid}
    >
      Marcar como pagada
    </Button>,
    null,
  ];

  /* <CustomButton
    disabled
    id="loadCSVButton"
    key="loadCSVButton"
    variant="orange-lined"
    onClick={loadCSV}
    className={classes.loadCSVButton}
  >
    Cargar CSV
  </CustomButton> */

  const actionsButtons = [
    /* {
      text: 'Agregar datos bancarios',
      action: addBankDetails,
    }, */
    /* {
      text: 'Pagar factura',
      action: payInvoice,
    }, */
    {
      text: 'Marcar como pagada',
      action: ({ invoice, closeMenu }) => {
        dispatch(paymentsPayInvoice(businessId, [invoice], moment().format()));
        closeMenu();
      },
    },
    {
      text: 'Archivar factura',
      action: ({ invoice, closeMenu }) => {
        dispatch(
          paymentsArchiveInvoice(businessId, [invoice], moment().format())
        );
        closeMenu();
      },
    },
  ];

  const handleSearchChange = value => {
    resetPagination();
    setSearch(value);
  };

  const handleUpdateInvoices = (newPage, newPageSize) => {
    updateInvoices({
      page: newPage + 1,
      pageSize: newPageSize,
      search,
      option: TABLE_NAME,
    });
  };

  useEffect(() => {
    updateInvoices({ page, pageSize, search, option: TABLE_NAME });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_INBOX_VIEWED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <PaymentsInvoiceTableToolbar
        title="Selecciona una o más facturas que quieras programar"
        description="Puedes programar el pago de estas facturas para hoy mismo o el día que crees conveniente."
        buttons={buttons}
        onChangeSearch={handleSearchChange}
      />

      <Card variant="white" border className={classes.card}>
        <PaymentsInvoiceTable
          rows={rows}
          actionsButtons={actionsButtons}
          countryId={countryId}
          handleUpdateInvoices={handleUpdateInvoices}
        />
      </Card>

      <PaymentsResume
        countryId={countryId}
        selectedInvoices={selectedInvoices}
        totalSelectedInvoices={totalSelectedInvoices}
        openStepper={handleShowScheduleStepper}
      />

      <PaymentsScheduleInvoicesStepper
        open={showScheduleInvoicesStepper}
        closeModal={() => setShowScheduleInvoicesStepper(false)}
      />
    </Grid>
  );
});

PaymentsInboxTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default PaymentsInboxTable;
