import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, Typography, Grid, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StepperContext from './StepperFlow/StepperContext';
import CustomButton from './elements/CustomButton';
import { paymentsSavePayroll } from '../store/actions/paymentsActions';
import PayrollResumeTable from './tables/PayrollResumeTable';
import LoadingButton from './elements/LoadingButton';
import { groupBy } from '../helpers/paymentsHelper';

import { SEGMENT_EVENTS } from '../helpers/segment/constants';
import { trackSegment } from '../helpers/segment/trackingSegment';

const RFC_KEY = 'identifier';

const useStyles = makeStyles(() => ({
  buttonsRoot: {
    marginTop: 28,
  },
  backButton: {
    marginRight: 22,
  },
  resumeTable: {
    margin: '40px 0 20px 0',
  },
}));

const PaymentsSchedulePaymentsStep3 = () => {
  const { state, currentStep, goBack, goNext } = useContext(StepperContext);
  const { payrollPaymentDate } = state;

  const [invoicesQuantities, setInvoicesQuantities] = useState(0);
  const [providersQuantities, setProvidersQuantities] = useState(0);

  const { selectedInvoices, totalSelectedInvoices } = useSelector(
    ({ paymentsState }) => paymentsState
  );
  const { id: businessId, countryId } = useSelector(
    ({ businessState }) => businessState.business
  );
  const { savePayrollLoading } = useSelector(
    ({ paymentsState }) => paymentsState
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSavePayroll = () => {
    const { payrollPaymentDate: paymentDate, payrollName: name } = state;
    const invoiceIds = selectedInvoices.map(invoice => invoice.id);
    dispatch(
      paymentsSavePayroll(businessId, name, paymentDate, invoiceIds, goNext)
    );
    trackSegment(SEGMENT_EVENTS.PAYMENT_ORDER_SCHEDULED, {
      businessId,
    });
  };

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      const filtered = groupBy(selectedInvoices, RFC_KEY);
      setProvidersQuantities(Object.keys(filtered).length);
      setInvoicesQuantities(selectedInvoices.length);
    }
  }, [selectedInvoices]);

  useEffect(() => {
    trackSegment(SEGMENT_EVENTS.PAYMENT_NAME_COMPLETED, {
      businessId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item>
      <Typography
        variant="h4"
        color="textPrimary"
        component="div"
        className={classes.title}
      >
        <Box fontWeight="fontWeightBold">Detalle de facturas por pagar</Box>
      </Typography>

      <Grid container item className={classes.resumeTable}>
        <PayrollResumeTable
          countryId={countryId}
          invoicesTotalAmount={totalSelectedInvoices}
          payrollPaymentDate={payrollPaymentDate}
          invoicesQuantities={invoicesQuantities}
          providersQuantities={providersQuantities}
        />
      </Grid>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          {currentStep > 0 && (
            <Grid item>
              <CustomButton
                variant="simple-gray"
                color="secondary"
                size="large"
                onClick={goBack}
                className={classes.backButton}
                disabled={savePayrollLoading}
              >
                Volver
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              variant="contained"
              color="secondary"
              disabled={savePayrollLoading}
              isLoading={savePayrollLoading}
              onClick={handleSavePayroll}
            >
              Programar pago
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentsSchedulePaymentsStep3;
