import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import CustomButton from './elements/CustomButton';
import CheckIcon from './icons/CheckIcon';
import { PAYMENTS_TABS } from '../helpers/constants';
import { setActiveTab } from '../store/actions/paymentsActions';
import StepperTitle from './elements/StepperTitle';
import StepperSubtitle from './elements/StepperSubtitle';

const useStyles = makeStyles(theme => ({
  buttonsRoot: {
    marginTop: 0,
  },
  check: {
    width: 48,
    height: 48,
    borderRadius: '100%',
    background: alpha(theme.palette.success.main, 0.2),
    marginBottom: 32,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));

const PaymentsSchedulePaymentsSuccess = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSuccessButton = () =>
    dispatch(setActiveTab(PAYMENTS_TABS.SCHEDULED));

  return (
    <Grid container item direction="column">
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        className={classes.check}
      >
        <CheckIcon className={classes.checkIcon} />
      </Grid>

      <StepperTitle>¡Pago programado con éxito!</StepperTitle>
      <StepperSubtitle>
        Hemos enviado tu solicitud a los responsables de aprobar esta operación.
        Una vez aprobada, podrás realizar el pago de esta.
      </StepperSubtitle>

      <Grid container item>
        <Grid container className={classes.buttonsRoot}>
          <CustomButton
            variant="all-orange"
            color="secondary"
            size="large"
            onClick={handleSuccessButton}
          >
            Ir a pagos
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentsSchedulePaymentsSuccess;
