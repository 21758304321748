import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { Fade, Box, Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { convertSpacingToCss } from '../helpers/stylesHelpers';
import Panel from './elements/Panel';
import CustomButton from './elements/CustomButton';
import {
  fetchBankAccounts,
  createBankAccount,
  updateBankAccount,
  setDefaultBankAccount,
  deleteBankAccount,
} from '../store/actions/bankAccountsActions';
import BankAccountItem from './BankAccountItem';
import GenericBankAccountDialog from './dialogs/GenericBankAccountDialog';
import ConfirmDialog from './dialogs/ConfirmDialog';

import { SEPARATOR } from '../theme/otherColors';

const useStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: SEPARATOR,
  },
  emptyContainer: {
    paddingLeft: 30,
  },
});

const BankAccounts = ({ sourceId, source, title, titleAction }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);
  const [showBankAccountDialog, setShowBankAccountDialog] = useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const [showDefaultAccountDialog, setShowDefaultAccountDialog] =
    useState(false);

  const {
    [`${source}BankAccounts`]: bankAccounts,
    [`${source}SetDefaultAccountError`]: setDefaultAccountError,
    [`${source}DeleteAccountError`]: deleteAccountError,
  } = useSelector(state => state.bankAccountsState);

  useEffect(() => {
    if (sourceId) {
      dispatch(fetchBankAccounts(sourceId, source));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId]);

  const handleCloseBankAccountDialog = () => {
    setSelectedBankAccount(null);
    setShowBankAccountDialog(false);
  };

  const handleShowEditDialog = bankAccountId => {
    const account = bankAccounts.find(
      bankAccount => bankAccount.id === bankAccountId
    );
    setSelectedBankAccount(account);
    setShowBankAccountDialog(true);
  };

  const handleSubmitBankAccount = bankAccountData => {
    const bankAccountObject = { ...bankAccountData };

    if (bankAccountObject.id) {
      const {
        Bank,
        id: bankAccountId,
        createdAt,
        updatedAt,
        ...restOfBankAccount
      } = bankAccountObject;
      delete restOfBankAccount.sourceId;
      delete restOfBankAccount.source;
      dispatch(
        updateBankAccount(sourceId, source, bankAccountId, restOfBankAccount)
      );
    } else {
      delete bankAccountObject.id;
      dispatch(createBankAccount(sourceId, source, bankAccountObject));
    }
  };

  const handleShowDeleteAccountDialog = bankAccountId => {
    setSelectedBankAccountId(bankAccountId);
    setShowDeleteAccountDialog(true);
  };

  const handleShowDefaultAccountDialog = bankAccountId => {
    setSelectedBankAccountId(bankAccountId);
    setShowDefaultAccountDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setShowDeleteAccountDialog(false);
    setShowDefaultAccountDialog(false);
    setSelectedBankAccountId(null);
  };

  const handleDeleteBankAccount = () => {
    dispatch(deleteBankAccount(sourceId, source, selectedBankAccountId));
    if (!deleteAccountError) handleCloseConfirmDialog();
  };

  const handleSetDefaultAccount = () => {
    dispatch(setDefaultBankAccount(sourceId, source, selectedBankAccountId));
    if (!setDefaultAccountError) handleCloseConfirmDialog();
  };

  return (
    <Fade in timeout={400}>
      <Grid item>
        {showDeleteAccountDialog && (
          <ConfirmDialog
            isOpen={showDeleteAccountDialog}
            handleClose={() => setShowDeleteAccountDialog(false)}
            title="Eliminar cuenta bancaria"
            message="¿Seguro deseas eliminar la cuenta bancaria? La podrás agregar nuevamente si deseas."
            buttonLabel="Eliminar cuenta"
            buttonOnAccept={handleDeleteBankAccount}
          />
        )}
        {showDefaultAccountDialog && (
          <ConfirmDialog
            isOpen={showDefaultAccountDialog}
            handleClose={handleCloseConfirmDialog}
            title="Cambiar cuenta predeterminada"
            message="Si cambias tu cuenta predeterminada ahora, será la que usaremos para transferirte tus futuras operaciones. Necesitamos que confirmes este cambio."
            buttonLabel="Cambiar cuenta"
            buttonOnAccept={handleSetDefaultAccount}
          />
        )}
        {showBankAccountDialog && (
          <GenericBankAccountDialog
            open={showBankAccountDialog}
            onClose={handleCloseBankAccountDialog}
            onSubmit={handleSubmitBankAccount}
            isRegister={!selectedBankAccount}
            bankAccount={selectedBankAccount}
            source={source}
          />
        )}

        <Box className={classes.containerBody}>
          <Panel
            title={title}
            variant="green-title"
            titlePadding="xl xl lg"
            contentPadding="zero zero xl zero"
            titleAction={titleAction}
            actions={
              sourceId ? (
                <CustomButton
                  variant="orange-lined"
                  onClick={() =>
                    setShowBankAccountDialog(!showBankAccountDialog)
                  }
                >
                  Agregar Cuenta
                </CustomButton>
              ) : null
            }
          >
            {bankAccounts?.length ? (
              bankAccounts
                .filter(bankAccount => !bankAccount.isXepelin)
                .map(bankAccount => (
                  <BankAccountItem
                    key={`item-${bankAccount.id}`}
                    bankAccount={bankAccount}
                    handleShowEditDialog={handleShowEditDialog}
                    handleShowDeleteAccountDialog={
                      handleShowDeleteAccountDialog
                    }
                    handleShowDefaultAccountDialog={
                      handleShowDefaultAccountDialog
                    }
                  />
                ))
                .reduce((accu, elem) => {
                  return accu === null
                    ? [elem]
                    : [
                        ...accu,
                        <div
                          key={`key${accu.length}`}
                          className={classes.separatorBottom}
                        />,
                        elem,
                      ];
                }, null)
            ) : (
              <Container className={classes.emptyContainer}>
                <Typography variant="body1" component="div">
                  No hay cuentas bancarias para mostrar
                </Typography>
              </Container>
            )}
          </Panel>
        </Box>
      </Grid>
    </Fade>
  );
};

BankAccounts.defaultProps = {
  titleAction: null,
};

BankAccounts.propTypes = {
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.element,
  source: PropTypes.string.isRequired,
  sourceId: PropTypes.number.isRequired,
};

export default BankAccounts;
