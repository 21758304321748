import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  filterContainer: {
    margin: '18px 0 28px 0',
  },
  icon: {
    width: 48,
    height: 48,
    margin: '0 16px 0 0',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const PaymentsStatSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item direction="column" container>
        <Grid item>
          <Skeleton animation="wave" variant="rect" width={104} height={24} />
        </Grid>
        <Grid item>
          <Grid item container className={classes.filterContainer}>
            <Skeleton animation="wave" variant="rect" width={174} height={24} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Box className={classes.icon}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={48}
              height={48}
            />
          </Box>
        </Grid>
        <Grid item>
          <Skeleton animation="wave" variant="rect" width={125} height={48} />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentsStatSkeleton;
