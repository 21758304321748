import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import CustomButton from './CustomButton';
import CountryFormatHelper from './CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';

const RFC_KEY = 'identifier';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 88,
    background: theme.palette.primary.main,
    borderRadius: '8px 8px 0px 0px',
    padding: '16px 28px 0 32px',
    boxSizing: 'border-box',
    position: 'sticky',
    bottom: 0,
    zIndex: 40,
    marginTop: 'auto',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 400px',
  },
  totalLine1: {
    ...theme.typography.h5,
    margin: '0 0 8px 0',
    color: theme.palette.primary.contrastText,
  },
  totalLine2: {
    ...theme.typography.subtitle1,
    margin: 0,
    color: theme.palette.primary.contrastText,
  },
  scheduleButton: {
    width: '100%',
    padding: '16px 48px',
    minHeight: 56,
    ...theme.typography.button,
    fontSize: theme.typography.h6.fontSize,
  },
}));

const PaymentsResume = props => {
  const classes = useStyles();
  const [providerQuantity, setProviderQuantity] = useState(0);
  const [invoiceQuantity, setInvoiceQuantity] = useState(0);
  const { countryId, selectedInvoices, totalSelectedInvoices, openStepper } =
    props;

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      const cloneResult = { ...result };
      (cloneResult[currentValue[key]] =
        cloneResult[currentValue[key]] || []).push(currentValue);
      return cloneResult;
    }, {});
  };

  useEffect(() => {
    if (selectedInvoices.length > 0) {
      const filtered = groupBy(selectedInvoices, RFC_KEY);
      setProviderQuantity(Object.keys(filtered).length);
      setInvoiceQuantity(selectedInvoices.length);
    }
  }, [selectedInvoices]);

  if (selectedInvoices.length <= 0) return null;

  return (
    <Box className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item>
          <Typography
            variant="body1"
            color="textPrimary"
            component="div"
            className={classes.totalLine1}
          >
            <Box fontWeight="fontWeightBold">
              Has seleccionado {providerQuantity} proveedores y{' '}
              {invoiceQuantity} facturas
            </Box>
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="div"
            className={classes.totalLine2}
          >
            <Box fontWeight="fontWeightBold">
              Tu costo de operación para estos pagos es de $0
            </Box>
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton
            variant="all-white"
            className={classes.scheduleButton}
            onClick={openStepper}
          >
            <>
              Programar pago por&nbsp;
              <CountryFormatHelper
                value={totalSelectedInvoices}
                countryId={countryId}
                variant="currency"
              />
            </>
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

PaymentsResume.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  selectedInvoices: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    })
  ).isRequired,
  totalSelectedInvoices: PropTypes.number.isRequired,
  openStepper: PropTypes.func.isRequired,
};

export default PaymentsResume;
