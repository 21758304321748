import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SearchBar from './SearchBar';

const useStyles = makeStyles(theme => ({
  toolbar: {
    margin: '0 0 24px 0',
    alignItems: ({ leftButtons, rightButtons }) =>
      leftButtons === null && rightButtons === null ? 'flex-end' : 'center',
  },
  toolbarWithButtons: {
    marginTop: 32,
  },
  title: {
    ...theme.typography.h5,
    margin: '0 0 16px 0',
  },
  description: {
    ...theme.typography.h6,
    margin: 0,
  },
}));

const TitleAndDescription = props => {
  const classes = useStyles(props);
  const { title, description } = props;
  return (
    <Box>
      {title && (
        <Typography
          variant="body1"
          color="textPrimary"
          component="h2"
          className={classes.title}
        >
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      )}
      {description && (
        <Typography
          variant="body1"
          color="textPrimary"
          component="h4"
          className={classes.description}
        >
          <Box>{description}</Box>
        </Typography>
      )}
    </Box>
  );
};

const PaymentsInvoiceTableToolbar = props => {
  const { title, description, buttons, onChangeSearch } = props;
  const classes = useStyles(props);
  const [buttonElements, setButtonElements] = useState([]);

  useEffect(() => {
    if (buttons) {
      const list = [...buttons];
      const half = Math.ceil(list.length / 2);
      const firstHalf = list.splice(0, half);
      const secondHalf = list.splice(-half);
      const newButtons = [firstHalf, secondHalf];
      setButtonElements(newButtons);
    }
  }, [buttons]);

  const areButtonsAvailable = () => buttonElements?.length > 0;

  const handleSearchBarChange = value => {
    onChangeSearch(value);
  };

  return (
    <Box>
      {areButtonsAvailable() && (
        <TitleAndDescription title={title} description={description} />
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.toolbar, {
          [classes.toolbarWithButtons]: areButtonsAvailable(),
        })}
      >
        <Grid item>
          {!areButtonsAvailable() && (
            <TitleAndDescription title={title} description={description} />
          )}
          {buttonElements?.[0]}
        </Grid>
        <Grid item display="flex" alignItems="center">
          {buttonElements?.[1]}
          <SearchBar handleChangeInput={handleSearchBarChange} />
        </Grid>
      </Grid>
    </Box>
  );
};

PaymentsInvoiceTableToolbar.defaultProps = {
  description: <>&nbsp;</>,
  buttons: null,
  onChangeSearch: () => {},
};

PaymentsInvoiceTableToolbar.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChangeSearch: PropTypes.func,
};

TitleAndDescription.defaultProps = {
  description: <>&nbsp;</>,
};

TitleAndDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default PaymentsInvoiceTableToolbar;
