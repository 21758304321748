import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { VALIDATION_TYPES, VALIDATION_MESSAGES } from '../helpers/constants';
import InfoField from './elements/InfoField';
import Panel from './elements/Panel';
import SupplierAvatar from './elements/SupplierAvatar';
import BankAccounts from './BankAccounts';
import ContactDetails from './ContactDetails';
import { cleanupBankAccount } from '../store/actions/bankAccountsActions';
import { cleanupContactDetail } from '../store/actions/contactDetailActions';
import { setSupplier, updateSupplier } from '../store/actions/suppliersActions';

const SOURCE = 'supplier';
const CONTACTABLE_TYPE = 'supplier';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: 40,
    overflow: 'auto',
    background: 'white',
  },
  avatar: {
    paddingBottom: 40,
    width: '100%',
  },
  paymentTerm: {
    paddingBottom: 30,
    width: '100%',
  },
  bankAccounts: {
    paddingBottom: 30,
    width: '100%',
  },
  contactDetails: {
    width: '100%',
  },
  panel: {
    marginBottom: 30,
  },
}));

const SupplierProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { REQUIRED } = VALIDATION_TYPES;
  const { REQUIRED_MSG } = VALIDATION_MESSAGES;

  const {
    id: sourceId,
    businessId,
    name,
    identifier,
    paymentTerm,
  } = useSelector(state => state.suppliersState.supplier) || {};
  const { supplierUpdateIsLoading } =
    useSelector(state => state.suppliersState) || {};

  const handleUpdateSupplierPaymentTerm = value => {
    dispatch(updateSupplier(businessId, sourceId, { paymentTerm: value }));
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(setSupplier(null));
      dispatch(cleanupBankAccount('supplier'));
      dispatch(cleanupContactDetail('supplier'));
    };
  }, [dispatch]);

  return (
    <Grid container alignContent="flex-start" className={classes.root}>
      <Grid item className={classes.avatar}>
        <SupplierAvatar name={name} identifier={identifier} />
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <Panel
          variant="green-title"
          title="Términos de pago"
          subtitle="Ingresa cada cuántos días realizas los pagos a este proveedor"
        >
          {sourceId ? (
            <InfoField
              label="Días de crédito"
              labelVariant="caption"
              labelColor="textPrimary"
              data={paymentTerm}
              loading={supplierUpdateIsLoading}
              dataVariant="body1"
              dataColor="textPrimary"
              dataFontWeight="fontWeightBold"
              onAccept={handleUpdateSupplierPaymentTerm}
              editable
              inputValidators={[REQUIRED]}
              inputErrorMessages={[REQUIRED_MSG]}
            />
          ) : (
            <Typography variant="caption" component="div">
              Días de crédito
            </Typography>
          )}
        </Panel>
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <BankAccounts
          sourceId={sourceId}
          source={SOURCE}
          title="Cuentas Bancarias"
        />
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <ContactDetails
          contactableId={sourceId}
          contactableType={CONTACTABLE_TYPE}
          title="Contacto"
        />
      </Grid>
    </Grid>
  );
};

export default SupplierProfile;
