import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  cleanupBankAccount,
  setBankAccountSourceObject,
} from '../store/actions/bankAccountsActions';
import { cleanupContactDetail } from '../store/actions/contactDetailActions';
import { fetchSuppliers, setSupplier } from '../store/actions/suppliersActions';
import Card from './elements/Card';
import Loader from './elements/Loader';
import SupplierAvatar from './elements/SupplierAvatar';
import SearchBar from './elements/SearchBar';
import { uniqueByKeepLast } from '../helpers/storeUtils';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: 40,
    overflow: 'auto',
  },
  card: {
    marginBottom: 10,
  },
  cardAction: {
    borderRadius: 16,
    boxSizing: 'border-box',
    padding: 20,
  },
  search: {
    marginBottom: 30,
  },
  bookIndexChar: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const SuppliersBook = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchProviderTerm, setSearchProviderTerm] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const business = useSelector(state => state.businessState.business);
  const {
    suppliers: fetchedSuppliers,
    pagination: { page, totalPages, pageSize },
  } = useSelector(state => state.suppliersState);

  const handleOnSelectSupplier = supplier => () => {
    dispatch(cleanupBankAccount('supplier'));
    dispatch(cleanupContactDetail('supplier'));
    dispatch(setSupplier(supplier));
    dispatch(setBankAccountSourceObject('supplier', supplier));
  };

  const handleChangeSearchInput = value => {
    setSearchProviderTerm(value);
  };

  const suppliersByLetter = suppliers.reduce((acc, supplier) => {
    const firstChar = supplier.name.toUpperCase()[0];
    if (!(firstChar in acc)) acc[firstChar] = [];
    acc[firstChar].push(supplier);
    return acc;
  }, {});

  const fetchMoreSuppliers = () => {
    if (page < totalPages) {
      dispatch(
        fetchSuppliers({
          businessId: business.id,
          page: page + 1,
          pageSize,
          name: searchProviderTerm,
        })
      );
    }
  };

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  useEffect(() => {
    setSuppliers([]);
    if (business) {
      dispatch(
        fetchSuppliers({
          businessId: business.id,
          name: searchProviderTerm,
          pageSize,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProviderTerm, business, dispatch]);

  useEffect(() => {
    setSuppliers(
      uniqueByKeepLast([...suppliers, ...fetchedSuppliers], s => s.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSuppliers]);

  return (
    <Grid
      container
      alignContent="flex-start"
      className={classes.root}
      id="scrollableDiv"
    >
      <Grid item xs={12} className={classes.search}>
        <SearchBar handleChangeInput={handleChangeSearchInput} />
      </Grid>

      <Grid item xs={12}>
        <InfiniteScroll
          dataLength={suppliers.length}
          next={fetchMoreSuppliers}
          hasMore={hasMore}
          loader={<Loader />}
          scrollableTarget="scrollableDiv"
        >
          {Object.entries(suppliersByLetter).map(([key, supps]) => (
            <div Grid key={key}>
              <Grid item xs={12} className={classes.bookIndexChar}>
                <Typography variant="h5" component="div" color="textPrimary">
                  <Box fontWeight="fontWeightBold">{key}</Box>
                </Typography>
              </Grid>
              {supps.map(supplier => (
                <Grid item xs={12} key={supplier.id}>
                  <Card variant="white" border className={classes.card}>
                    <CardActionArea
                      onClick={handleOnSelectSupplier(supplier)}
                      className={classes.cardAction}
                    >
                      <SupplierAvatar
                        name={supplier.name}
                        identifier={supplier.identifier}
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </div>
          ))}
        </InfiniteScroll>
      </Grid>
    </Grid>
  );
};

export default SuppliersBook;
